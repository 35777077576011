import * as Components from "SiteComponents";
import { GridItemStyleProps } from "../Grid/GridItem";
import { GridContainerStyleProps } from "../Grid/GridContainer";

export interface GridProps {
  gridContainerProps: GridContainerStyleProps;
  gridItemProps: GridItemStyleProps;
}

type LayoutBlockGridPropsType = {
  [Key in keyof typeof Components]?: GridProps;
};

const LayoutBlockGridProps: LayoutBlockGridPropsType = {
  AccordionListBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      xs: 12,
      lg: 6,
      lgOffset: 3,
    },
  },
  AlertBlock: {
    gridContainerProps: {},
    gridItemProps: {
      lg: 12,
    },
  },
  Button: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
      sm: 6,
    },
  },
  CardListBlock: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
    },
  },
  ContactBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      xs: 12,
      lg: 6,
      lgOffset: 3,
    },
  },
  ContentManagerBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      xs: 12,
    },
  },
  CtaBlock: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
    },
  },
  CtaLinkBlock: {
    gridContainerProps: { noTopMargin: true },
    gridItemProps: {
      xs: 12,
      lg: 6,
      lgOffset: 3,
    },
  },
  DownloadLinkListBlock: {
    gridContainerProps: {noTopMargin: true},
    gridItemProps: {
      lg: 6,
      lgOffset: 3,
    },
  },
  FeaturedContentBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 12,
      xs: 12,
    },
  },
  GenericQuoteBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 6,
      lgOffset: 3,
    },
  },
  GlobalLinkListBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 12,
      xs: 12,
    },
  },
  GlobalCardBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 6,
      xs: 12,
      lgOffset: 3,
    },
  },
  HighlightedInfoBlock: {
    gridContainerProps: {},
    gridItemProps: {
      lg: 6,
      lgOffset: 3,
    },
  },
  MultiMediaBlock: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 6,
      lgOffset: 3,
    },
  },
  RelatedArticlesBlock: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
    },
  },
  RichTextBlock: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
    },
  },
  RichText: {
    gridContainerProps: { horizontalSpacing: "lg" },
    gridItemProps: {
      lg: 6,
      lgOffset: 3,
    },
  },
  SingleCardBlock: {
    gridContainerProps: {
      horizontalSpacing: "lg",
      noVertical: true,
    },
    gridItemProps: {
      xs: 12,
      lg: 6,
      lgOffset: 3,
    },
  },
  StatisticsBlock: {
    gridContainerProps: {},
    gridItemProps: {
      xs: 12,
      lg: 6,
      lgOffset: 3,
    },
  },
};

const defaultValue: GridProps = {
  gridContainerProps: {},
  gridItemProps: {
    xs: 12,
  },
};

const LayoutBlockGridPropsProxy = new Proxy(LayoutBlockGridProps, {
  get: function (target, prop: keyof typeof Components, receiver) {
    // Check if the property exists in the target
    if (prop in target) {
      return target[prop];
    }
    // Return the default value if the property does not exist
    return defaultValue;
  },
});

export default LayoutBlockGridPropsProxy;
